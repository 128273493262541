/* eslint-disable import/no-import-module-exports */
/**
 * Create the main React application.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'react-hot-loader/patch';
import { AppContainer } from 'react-hot-loader';
import ScrumApp from './ScrumApp';
import store from './store';
import initAxios from './axios.ts';
import initEcho from './echo.ts';
import initCsrf from './csrf.ts';
import './components/Alerts';

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 *
 * @todo Audit the bootstrap features we are using and list them explicitly here.
 */
import 'bootstrap';

// Important! Axios must be initialized before Echo and the CSRF refresh.
initAxios();

initEcho();
initCsrf();

window.dispatchAlert = (body, type = 'info', timeout = 7500) => {
  const event = new CustomEvent('newalert', {
    detail: { body, type, timeout },
  });
  document.dispatchEvent(event);
};

const el = document.getElementById('appBinding');
if (el) {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}><ScrumApp /></Provider>
    </AppContainer>,
    el
  );
}

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept();
}
