export default function init() {
  // Refresh the CSRF token every hour.
  setInterval(
    () => axios.get('/sanctum/csrf-cookie').catch((error) => {
      dispatchAlert('Something went wrong behind-the-scenes, please refresh your browser.', 'danger', 3599900);
      // eslint-disable-next-line no-console
      console.error(error);
    }),
    3600000
  );
}
