import { batch } from 'react-redux';

import { setUiFlag } from '../entity';
import { PUSHER_INIT_MY_CHANNELS } from '../types';
import joinTeamChannel from './joinTeamChannel';

export default () => (dispatch, getState) => {
  const {
    data: { entities: { teams } },
    appMeta: {
      user_id: userId,
      current_team: currentTeamId,
    },
  } = getState();

  window.Echo?.connector.pusher?.connection.bind('state_change', (states) => dispatch(
    setUiFlag({ pusherState: states.current })
  ));

  batch(() => {
    const myTeams = Object.keys(teams)
      .filter((teamId) => teamId === currentTeamId || teams?.[teamId]?.members?.includes(userId))
      .map((teamId) => {
        dispatch(joinTeamChannel(teamId));
        return `teams.${teamId}`;
      });

    dispatch({
      type: PUSHER_INIT_MY_CHANNELS,
      myTeams,
    });
  });
};
