/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allow your team to quickly build robust real-time web applications.
 */
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

declare global {
  interface Window {
    Echo: Echo<'reverb'|'pusher'|'null'>;
    Pusher: typeof Pusher;
  }
}

export default function init() {
  window.Pusher = Pusher;

  const driver = process.env.MIX_BROADCAST_DRIVER;

  const authorizer = (channel) => ({
    authorize: (socketId, callback) => {
      axios.post('/api/broadcasting/auth', {
        socket_id: socketId,
        channel_name: channel.name,
      }, {
        headers: {
          'X-Active-Team': 'ignore',
        },
      })
        .then((response) => {
          callback(false, response.data);
        })
        .catch((error) => {
          callback(true, error);
        });
    },
  });

  if (driver === 'reverb') {
    window.Echo = new Echo({
      broadcaster: 'reverb',
      key: process.env.MIX_REVERB_APP_KEY,
      wsHost: process.env.MIX_REVERB_HOST,
      wsPort: process.env.MIX_REVERB_PORT ?? 80,
      wssPort: process.env.MIX_REVERB_PORT ?? 443,
      forceTLS: (process.env.MIX_REVERB_SCHEME ?? 'https') === 'https',
      enabledTransports: ['ws', 'wss'],
      encrypted: true,
      authorizer,
    });
  } else if (driver === 'pusher') {
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.MIX_PUSHER_APP_KEY,
      cluster: process.env.MIX_PUSHER_APP_CLUSTER,
      forceTLS: true,
      encrypted: true,
      authorizer,
    });
  } else {
    window.Echo = new Echo({
      broadcaster: 'null',
    });
  }
}
