/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios, { AxiosRequestConfig, AxiosStatic } from 'axios';

declare global {
  interface Window {
    axios: AxiosStatic & {
      defaults: AxiosRequestConfig & { withXSRFToken?: boolean };
    };
  }
}

export default function init() {
  window.axios = axios;

  window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  window.axios.defaults.withCredentials = true;
  window.axios.defaults.withXSRFToken = true;
}
