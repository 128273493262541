import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {
  Link, useParams, useHistory, useLocation,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { CheckCircleFill } from 'react-bootstrap-icons';
import classnames from 'classnames';
import { push } from 'connected-react-router';

// Internal dependencies.
import {
  STORY_SHAPE,
  SOURCE_SHAPE,
  USER_SHAPE,
} from '../../types/props';
import * as uri from '../../utils/uri';
import { getTeamMembersForCurrentTeam, makeGetSource, selectStory } from '../../selectors';
import * as actions from '../../actions';
import { errorHandler } from '../../services/api';
import { TYPE_MAP } from '../../actions/types';
import scrollToHash from '../../utils/scrollToHash';

// Components
import Attachments from './Attachments';
import Body from './Body';
import Comments from './Comments';
import ContributorPane from './ContributorPane';
import DateField from './DateField';
import FeatureLabel from './Features/FeatureLabel';
import FeatureSelect from './Features/FeatureSelect';
import EpicLabel from './Epics/EpicLabel';
import EpicSelect from './Epics/EpicSelect';
import Loading from '../Loading';
import Menu from '../Menu';
import Points from './Points';
import PokerGame from './PokerGame';
import SplitStory from './SplitStory';
import StoryChildren from './StoryChildren';
import StoryId from '../StoryId';
import TaskList from './Tasks/TaskList';
import Title from './Title';
import ConfirmationDialog from '../ConfirmationDialog';

// Styles
import styles from './Story.scss';

const storyPropTypes = {
  addComment: PropTypes.func.isRequired,
  addStoryContributor: PropTypes.func.isRequired,
  addTask: PropTypes.func.isRequired,
  cloneStory: PropTypes.func.isRequired,
  createFeature: PropTypes.func.isRequired,
  currentUserId: PropTypes.number.isRequired,
  currentTeamId: PropTypes.number.isRequired,
  deleteComment: PropTypes.func.isRequired,
  deleteStory: PropTypes.func.isRequired,
  deleteTask: PropTypes.func.isRequired,
  joinSomeChannel: PropTypes.func.isRequired,
  loadStory: PropTypes.func.isRequired,
  navigateTo: PropTypes.func.isRequired,
  removeEpic: PropTypes.func.isRequired,
  removeFeature: PropTypes.func.isRequired,
  removeStoryContributor: PropTypes.func.isRequired,
  setEpic: PropTypes.func.isRequired,
  setFeature: PropTypes.func.isRequired,
  splitStory: PropTypes.func.isRequired,
  startPokerGame: PropTypes.func.isRequired,
  story: PropTypes.shape(STORY_SHAPE),
  source: PropTypes.shape(SOURCE_SHAPE),
  teamMembers: PropTypes.arrayOf(PropTypes.shape(USER_SHAPE)).isRequired,
  updateComment: PropTypes.func.isRequired,
  updateStory: PropTypes.func.isRequired,
  updateTask: PropTypes.func.isRequired,
  updateTaskOrder: PropTypes.func.isRequired,
};

function Story({
  addComment,
  addStoryContributor,
  addTask,
  cloneStory,
  createFeature,
  currentTeamId,
  currentUserId,
  deleteComment,
  deleteStory,
  deleteTask,
  joinSomeChannel,
  loadStory,
  navigateTo,
  removeEpic,
  removeFeature,
  removeStoryContributor,
  setEpic,
  setFeature,
  source = null,
  splitStory,
  startPokerGame,
  story = null,
  teamMembers,
  updateComment,
  updateStory,
  updateTask,
  updateTaskOrder,
}) {
  const { id: contextId, contextType, storyId } = useParams();
  const [splittingStory, setSplittingStory] = useState(false);
  const [isModifyingFeature, setIsModifyingFeature] = useState(false);
  const [isModifyingEpic, setIsModifyingEpic] = useState(false);
  const [isConfirmingClose, setIsConfirmingClose] = useState(false);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const history = useHistory();
  const { hash } = useLocation();

  // Reset the state flags on unmount.
  useEffect(() => () => {
    setSplittingStory(false);
    setIsModifyingFeature(false);
    setIsModifyingEpic(false);
    setIsConfirmingClose(false);
    setIsConfirmingDelete(false);
  }, [storyId]);

  useEffect(() => {
    let leaveStoryChannel;
    let leaveContextChannel;
    let leaveSourceChannel;

    loadStory({
      id: storyId,
      source_type: contextType,
      source_id: contextId,
    })
      .then(() => {
        leaveStoryChannel = joinSomeChannel('story', storyId);
        leaveContextChannel = joinSomeChannel(contextType, contextId);
        if (source) {
          leaveSourceChannel = joinSomeChannel(source.type, source.id);
        }

        // Scroll to a hash, if applicable.
        scrollToHash(hash);
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          dispatchAlert('Story does not exist.', 'danger');
          // Navigate to the homepage.
          navigateTo(uri.board(currentTeamId));
        } else {
          errorHandler(error);
        }
      });

    return () => {
      if (typeof leaveStoryChannel === 'function') {
        leaveStoryChannel();
      }
      if (typeof leaveContextChannel === 'function') {
        leaveContextChannel();
      }
      if (typeof leaveSourceChannel === 'function') {
        leaveSourceChannel();
      }
    };
  }, [storyId, story?.context_link?.contextId, story?.context_link?.contextType]);

  // Ensure that the story's backlog matches the context, and if not, redirect.
  useEffect(() => {
    if (story?.backlog?.id) {
      const canonicalContextType = story.backlog.type === 'project' ? 'projects' : 'teams';
      const canonicalContextId = story.backlog.parent?.id
        ? story.backlog.parent.id
        : story.backlog.id;

      if (
        canonicalContextId
        && canonicalContextType
        && !(+canonicalContextId === +contextId && canonicalContextType === contextType)
      ) {
        navigateTo(`${uri.story(TYPE_MAP[canonicalContextType], canonicalContextId, storyId)}${hash}`);
      }
    }
  }, [storyId, story?.backlog?.id, story?.backlog?.type]);

  if (!story?.context_link) {
    return <Loading />;
  }

  const updateStoryHandler = (changed) => updateStory(story, changed);

  const closeStoryHandler = () => setIsConfirmingClose(true);
  const closeConfirmAction = () => {
    updateStory(story, { status: 'done' });
    setIsConfirmingClose(false);
  };

  const addCommentHandler = (comment) => addComment(story.id, comment);

  const updateCommentHandler = (commentId, body) => updateComment(commentId, { body });

  const deleteCommentHandler = (commentId) => deleteComment(commentId, story.id);

  const updateOrderHandler = (newOrder, initiator) => updateTaskOrder(story, newOrder, initiator);

  const deleteTaskHandler = (id) => deleteTask(story.id, id);

  const startPokerGameHandler = () => startPokerGame(story.id, contextType === 'teams' ? contextId : currentTeamId);

  const closeFeatureModal = () => setIsModifyingFeature(false);

  const closeEpicModal = () => setIsModifyingEpic(false);

  /**
   * Whether or not the story is split from an epic.
   * @type bool
   */
  const isChild = typeof story?.parent_id === 'number';

  const body = () => (
    <Body
      content={story.body || ''}
      updateStory={updateStoryHandler}
      pusherActivity={story.pusherActivity}
      fields={['body']}
      storyId={story.id}
    />
  );

  const withSpinner = (func, partial) => (partial ? 'Loading' : func());
  const canEstimate = () => !story.games;

  const splitHandler = (...args) => {
    splitStory(...args);
    setSplittingStory(false);
  };

  const deleteHandler = (e) => {
    e.preventDefault();
    setIsConfirmingDelete(true);
  };

  const deleteConfirmAction = () => {
    history.push(contextType === 'projects' ? uri.project(contextId) : uri.team(contextId));
    deleteStory(story, contextType, contextId);
  };

  const cloneHandler = () => {
    cloneStory(story.id)
      .then((clonedStory) => {
        dispatchAlert(
          (
            <>
              {'Story cloned! '}
              <a href={clonedStory.url}>Edit the new story</a>
            </>
          ),
          'success',
          15000
        );
        return clonedStory;
      });
  };

  /**
   * Handle saving a feature to the story.
   *
   * @param {Number|null} featureId - The feature to save, or null to remove.
   */
  const handleFeatureSave = (featureId) => {
    if (Number.isInteger(featureId)) {
      setFeature(story.id, featureId);
    } else {
      removeFeature(story.id, story?.feature?.id);
    }
    setIsModifyingFeature(false);
  };

  /**
   * Handle saving an epic to the story.
   *
   * @param {Number|null} epicId - The epic to save, or null to remove.
   */
  const handleEpicSave = (epicId) => {
    if (Number.isInteger(epicId)) {
      setEpic(story.id, epicId);
    } else if (story.parent?.id) {
      removeEpic(story.id, story.parent.id);
    }
    setIsModifyingEpic(false);
  };

  return (
    <>
      <Helmet>
        <title>
          {story.title}
        </title>
      </Helmet>
      <main className="container story-screen">
        <h1 className="sr-only">
          {story.title}
        </h1>
        <div className="row mb-3">
          <div className="col-md-9 col-xl-10">
            {splittingStory ? (
              <SplitStory
                story={story}
                splitHandler={splitHandler}
                cancelSplitHandler={() => setSplittingStory(false)}
                storyFeature={story.feature}
              />
            ) : null}

            {isModifyingFeature ? (
              <FeatureSelect
                closeModal={closeFeatureModal}
                source={source}
                createFeature={createFeature}
                handleFeatureSave={handleFeatureSave}
                storyFeature={story.feature || {}}
              />
            ) : null}

            {isModifyingEpic ? (
              <EpicSelect
                storyId={story.id}
                closeModal={closeEpicModal}
                source={source}
                handleEpicSave={handleEpicSave}
                storyEpic={story.parent || {}}
              />
            ) : null}

            {story.games ? <PokerGame game={story.games} /> : null}

            <div className={classnames('card mb-3', { 'border-success': story.status === 'done' })}>
              <div className={classnames('card-body', styles.card, {
                [styles.epic]: story?.children?.length > 0,
                [styles.child]: isChild,
              })}
              >
                <div className="d-flex mb-3 justify-content-end align-items-center">
                  <div className="h1 flex-grow-0 mr-2 mb-1">
                    <StoryId id={story.id} />
                  </div>

                  <div className="relationship-meta">
                    {contextType !== story.source_type && (
                    <div className="d-flex mb-1">
                      <Link
                        to={uri.source(source)}
                        className="badge badge-dark"
                      >
                        {source.label}
                      </Link>
                    </div>
                    )}

                    <div className="hierarchy-meta">
                      <FeatureLabel
                        title={story.feature?.title || undefined}
                        url={story.feature?.url}
                        isModifyingFeature={isModifyingFeature}
                        setIsModifyingFeature={setIsModifyingFeature}
                        fields={['feature']}
                        pusherActivity={story.pusherActivity}
                      />
                      {' '}
                      <span className="ml-1 mr-2">/</span>
                      {' '}
                      <EpicLabel
                        epic={story.parent}
                        isModifyingEpic={isModifyingEpic}
                        setIsModifyingEpic={setIsModifyingEpic}
                        fields={['parent_id', 'parent', 'epic']}
                        pusherActivity={story.pusherActivity}
                      />
                    </div>
                  </div>
                  <div className="flex-shrink-0">
                    <DateField
                      date={story.date}
                      setDate={(date) => updateStoryHandler({ date })}
                      pusherActivity={story.pusherActivity}
                      fields={['date']}
                    />
                  </div>
                  <Menu
                    className="flex-shrink-0"
                    headingLevel={2}
                    label="Story Management"
                  >
                    <a href={`/stories/${story.id}/activity`} className="dropdown-item">
                      Story History
                    </a>
                    <div className="dropdown-divider" aria-hidden />
                    <button type="button" className="dropdown-item" onClick={cloneHandler}>
                      Clone Story
                    </button>
                    <div className="dropdown-divider" aria-hidden />
                    {story.status !== 'done' && story?.backlog?.type !== 'sprint' ? (
                      <button type="button" className="dropdown-item" onClick={closeStoryHandler}>
                        Close Story
                      </button>
                    ) : null}
                    <form onSubmit={deleteHandler}>
                      <button type="submit" className="dropdown-item danger">
                        Delete Story
                      </button>
                    </form>
                  </Menu>
                </div>

                <Title
                  updateStory={updateStoryHandler}
                  title={story.title}
                  pusherActivity={story.pusherActivity}
                  fields={['title']}
                />

                {story.status === 'done' ? (
                  <span className="h2">
                    <span className="done-flag">
                      <CheckCircleFill />
                      {' Done! '}
                    </span>
                  </span>
                ) : null}

                <div className="mb-3">
                  {withSpinner(body, story.partial)}
                </div>

                <TaskList
                  tasks={story.tasks}
                  updateTask={updateTask}
                  deleteTask={deleteTaskHandler}
                  updateOrder={updateOrderHandler}
                  addTask={(text) => addTask(story.id, text)}
                  storyId={story.id}
                />

                {story.attachments?.length ? (
                  <Attachments attachments={story.attachments} storyId={story.id} />
                ) : null}
              </div>
            </div>

            {story.children?.length ? (
              <StoryChildren stories={story.children} parentContext={source} />
            ) : null}

            <Comments
              comments={story.comments}
              addComment={addCommentHandler}
              updateComment={updateCommentHandler}
              deleteComment={deleteCommentHandler}
              storyId={story.id}
              currentUserId={currentUserId}
            />
          </div>
          <div className="col-md-3 col-xl-2">
            <div className="card mb-3">
              <div className="card-body text-center">
                <Points
                  updateStory={updateStoryHandler}
                  deleteStory={updateStoryHandler}
                  startPokerGame={startPokerGameHandler}
                  canEstimate={canEstimate()}
                  contextId={contextId}
                  points={story.points}
                  pusherActivity={story.pusherActivity}
                  fields={['points']}
                />
                <button
                  className="btn btn-secondary btn-block mt-2"
                  onClick={() => setSplittingStory(!splittingStory)}
                  type="button"
                >
                  {splittingStory ? 'Cancel Split' : 'Split'}
                </button>
              </div>
            </div>
            <ContributorPane
              addStoryContributor={(userId) => addStoryContributor(story.id, userId)}
              contributors={story.contributors_data}
              currentUserId={currentUserId}
              removeStoryContributor={(userId) => removeStoryContributor(story.id, userId)}
              pusherActivity={story.pusherActivity}
              fields={['contributors_data']}
              teamMembers={teamMembers}
            />
          </div>
        </div>
        {isConfirmingClose ? (
          <ConfirmationDialog
            message="Are you sure you want to close this story?"
            additionalInfo="This story is not in a sprint, and closing it will not count toward the sprint velocity. To have this story count toward the current sprint, first move it into the sprint and then move it to the Done column."
            closeDialog={() => setIsConfirmingClose(false)}
            confirmAction={closeConfirmAction}
          />
        ) : null}
        {isConfirmingDelete ? (
          <ConfirmationDialog
            message="Are you sure you want to delete this story?"
            closeDialog={() => setIsConfirmingDelete(false)}
            confirmAction={deleteConfirmAction}
          />
        ) : null}
      </main>
    </>
  );
}

Story.propTypes = storyPropTypes;

export default connect(
  (state, ownProps) => {
    const getSource = makeGetSource();
    const source = getSource(state, ownProps?.match?.params);
    const currentTeamId = state.appMeta?.current_team || 0;
    return ({
      currentUserId: state.appMeta?.user_id,
      currentTeamId,
      story: selectStory(state, ownProps?.match?.params?.storyId),
      source: source?.id ? source : null,
      teamMembers: getTeamMembersForCurrentTeam(state),
    });
  },
  {
    addComment: actions.addStoryComment,
    addStoryContributor: actions.addStoryContributor,
    addTask: actions.addTask,
    cloneStory: actions.cloneStory,
    createFeature: actions.createFeature,
    deleteComment: actions.deleteComment,
    deleteStory: actions.deleteStory,
    deleteTask: actions.deleteTask,
    joinSomeChannel: actions.joinSomeChannel,
    loadStory: actions.loadStory,
    navigateTo: push,
    removeEpic: actions.removeEpic,
    removeFeature: actions.removeFeature,
    removeStoryContributor: actions.removeStoryContributor,
    setEpic: actions.setEpic,
    setFeature: actions.setFeature,
    splitStory: actions.splitStory,
    startPokerGame: actions.startPokerGame,
    updateComment: actions.updateComment,
    updateStory: actions.updateStory,
    updateTask: actions.updateTask,
    updateTaskOrder: actions.updateTaskOrder,
  }
)(Story);
